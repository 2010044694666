import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import AirportTransportation from "./../components/Airport-transportation/index";

const AirportTransport = () => (
	<Layout>
		<SEO
			title="Taxi Hamburg Airport | Taxi Neumünster to Hamburg Airport"
			meta="Taxi Kiel to Hamburg Airport | Taxi Neumünster to Hamburg Airport"
			description="Pre-book your taxi ride for a better traveling experience from Hamburg airport to your destination at a cheap price."
		/>
		<AirportTransportation />
	</Layout>
);

export default AirportTransport;
